import React from "react";
import dynamic from "next/dynamic";
import NavItem from "./NavItem";
import Link from "next/link";
import { Div, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
const Avatar = dynamic(() => import("./Avatar"), {
  ssr: false,
});
import { Theme } from "@/outscal-commons-frontend/Styled/Theme";
const OutscalAnimatedLogo = dynamic(() =>
  import("@/Components/OutscalAnimatedLogo")
);
import { BurgerMenuLogo, MenuItems } from "./style";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import { GoCodeReview } from "react-icons/go";
import styled from "styled-components";
import { useRouter } from "next/router";

const Root = styled(RowDiv)`
  width: 100%;
  min-height: 68px;
  height: 68px;
  @media screen and (max-width: 500px) {
    display: none;
  }
`;

const CodeReviewNotification = styled(Div)`
  position: relative;
`;

const CodeReviewIcon = styled(GoCodeReview)`
  cursor: pointer;
  font-size: ${(p) => p.theme.fontSize.xxxxl};
  color: ${(p) => p.theme.colors.themeYellow};
`;

const NotificationCount = styled.span`
  position: absolute;
  top: -10px;
  right: -5px;
  background-color: ${(p) => p.theme.colors.themeYellow};
  border-radius: ${(p) => p.theme.borderRadius.round};
  font-size: ${(p) => p.theme.fontSize.xs};
  padding: 4px 7px;
`;

const TopNavBar = ({ data, setNavDrawerOpen }) => {
  const router = useRouter();
  const { user } = useUserActions();

  if (!data) {
    return null;
  }

  const hasNavList = data.navList && Object.keys(data.navList).length > 0;
  const hasProfileMenu =
    data.profileMenu && Object.keys(data.profileMenu).length > 0;

  return (
    <Root justifyContent="space-between" alignItems="center">
      <RowDiv alignItems="center" gap="30px">
        <MenuItems>
          <Link href="/">
            <OutscalAnimatedLogo />
          </Link>
          {hasNavList &&
            Object.values(data.navList).map((item) => (
              <NavItem key={item.id} item={item} />
            ))}
        </MenuItems>
        <BurgerMenuLogo
          onClick={() => {
            setNavDrawerOpen(true);
          }}
          color={Theme.colors.themeYellow}
          size="40px"
        />
      </RowDiv>
      <RowDiv gap="20px" alignItems="center">
        {hasProfileMenu && <Avatar menu={data.profileMenu} />}
        {user?.notifications?.reviews?.length > 0 && (
          <CodeReviewNotification
            onClick={() => {
              router.push("/reviews?reviewStatus=2");
            }}
          >
            <CodeReviewIcon />
            <NotificationCount>
              {user?.notifications?.reviews?.length}
            </NotificationCount>
          </CodeReviewNotification>
        )}
      </RowDiv>
    </Root>
  );
};

export default React.memo(TopNavBar);
