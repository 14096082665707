import { RowDiv } from "@/outscal-commons-frontend/Styled";
import useAuthActions from "@/store/actions/authAction";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import Link from "next/link";
import React from "react";
import styled from "styled-components";
import Image from "next/image";
import RotateDiscordIcon from "./RotateDiscordIcon";
import NonLoggedInAvatar from "./NonLoggedInAvatar";

const Menu = styled.div`
  background-color: ${(p) => p.theme.colors.white};
  margin-top: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px;
  padding: 15px;
  margin-top: 65px;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  display: none;
  width: max-content;
  right: auto;
  flex-direction: column;
  font-size: ${(p) => p.theme.fontSize.sm};
  font-weight: ${(p) => p.theme.fontWeight.small};
`;

const MenuWrapper = styled.div`
  position: absolute;
  min-width: 50%;
  min-height: 100%;
  left: auto;
  right: 0px;
  top: 0;
  height: auto;
  width: max-content;
  &:hover ${Menu} {
    display: flex;
  }
`;

const Root = styled.div`
  width: auto;
  position: relative;
`;

const Item = styled.div`
  cursor: pointer;
`;

const MenuItem = styled(RowDiv)`
  cursor: pointer;
  align-items: center;
  gap: 10px;
  border-radius: ${(p) => p.theme.borderRadius.sm};
  padding: 10px 20px;
  &:hover {
    background-color: ${(p) => p.theme.colors.fadedYellow};
  }
`;

const Row = styled(RowDiv)`
  align-items: center;
  gap: 16px;
  transition: all 0.8s ease-in-out;
  scale: 1;
`;

const getProfilePicture = (user) => {
  let profilePicture = null;
  if (user?.userDetails?.profile_picture) {
    profilePicture = user.userDetails.profile_picture;
  } else {
    const parsedData = JSON.parse(localStorage.getItem("userData"));
    if (parsedData) {
      profilePicture = parsedData.profile_picture;
    }
  }
  return profilePicture;
};

function Avatar({ menu }) {
  const { clearToken } = useAuthActions();
  const { clearDetails, user } = useUserActions();
  const { auth } = useAuthActions();

  const profilePicture = getProfilePicture(user);

  return (
    <Root>
      {auth?.token ? (
        <>
          {profilePicture && (
            <Row>
              {auth?.token && <RotateDiscordIcon />}
              <Image
                style={{
                  objectFit: "cover",
                  borderRadius: "50%",
                  cursor: "pointer",
                }}
                src={
                  profilePicture ||
                  "https://outscal-assets.s3.ap-south-1.amazonaws.com/user/profile_picture/game_profile_pic3.png"
                }
                alt="user_profile_avatar"
                width={60}
                height={60}
                quality={50}
              />
            </Row>
          )}
          <MenuWrapper>
            <Menu>
              {Object.values(menu).map((item, i) => {
                let Icon = item.icon;
                return (
                  <div key={item.id}>
                    {item.link && (
                      <Link key={item.id + item.link} href={item.link}>
                        <MenuItem>
                          <Icon />
                          <Item>{item.label}</Item>
                        </MenuItem>
                      </Link>
                    )}
                    {item.action && (
                      <MenuItem
                        key={item.id + i}
                        onClick={() => {
                          if (item.id === "logout") {
                            item.action(() => {
                              clearToken();
                              clearDetails();
                              localStorage.clear();
                              sessionStorage.clear();
                            });
                          } else {
                            item.action();
                          }
                        }}
                      >
                        <Icon />
                        <Item>{item.label}</Item>
                      </MenuItem>
                    )}
                  </div>
                );
              })}
            </Menu>
          </MenuWrapper>
        </>
      ) : (
        <Row>
          {/* {auth?.token && <RotateDiscordIcon />} */}
          <NonLoggedInAvatar />
        </Row>
      )}
    </Root>
  );
}

export default Avatar;
