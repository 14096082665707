import BaseEnum from "./BaseEnum";

const UserScholarshipStatusEnum = new BaseEnum(
  [
    "application-started",
    "in-progress",
    "accepted",
    "rejected",
    "auto-approved",
  ],
  [
    "Application Started",
    "In Progress",
    "Accepted",
    "Rejected",
    "Auto Approved",
  ]
);

export default UserScholarshipStatusEnum;
