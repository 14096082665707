import {
  CourseTypeEnum,
  UserCourseStatusEnum,
  UserScholarshipStatusEnum,
} from "@/outscal-commons-frontend/Enums";
import { givenDatesDaysDifference } from "@/outscal-commons-frontend/utils/dateUtils";

const SHOW_SCHOLARSHIP_PLUG_AFTER_DAYS = 60;

export const showScholarshipPlug = (user) => {
  const userScholarships = user?.userScholarships || [];
  const allUserCourseStatus = user?.userCourseStatus || [];
  let isScholarshipApplicationInComplete = false;
  let isScholarshipApplicationCompleted = false;

  for (let scholarship of userScholarships) {
    if (
      scholarship.current_status?.status ===
      UserScholarshipStatusEnum.getValue("application-started")
    ) {
      isScholarshipApplicationInComplete = true;
    } else if (
      scholarship.current_status?.status == UserScholarshipStatusEnum.getValue("in-progress") || scholarship.current_status?.status == UserScholarshipStatusEnum.getValue("auto-approved")
    ) {
      isScholarshipApplicationCompleted = true;
    }
  }

  if (isScholarshipApplicationCompleted) {
    return false;
  }

  if (isScholarshipApplicationInComplete) {
    return true;
  }

  for (let userCourseStatus of allUserCourseStatus) {
    const currentStatus = userCourseStatus?.current_status;

    if (
      userCourseStatus?.course?.course_type ===
      CourseTypeEnum.getValue("paid") &&
      (currentStatus?.status === UserCourseStatusEnum.getValue("started") ||
        currentStatus?.status ===
        UserCourseStatusEnum.getValue("access-granted"))
    ) {
      return false;
    }

    if (
      userCourseStatus?.course?.course_type ===
      CourseTypeEnum.getValue("paid") &&
      currentStatus?.status === UserCourseStatusEnum.getValue("waitlisted")
    ) {
      const waitlistDate = currentStatus?.createdAt;
      const numberOfDays = givenDatesDaysDifference(waitlistDate, new Date());

      if (numberOfDays <= SHOW_SCHOLARSHIP_PLUG_AFTER_DAYS) {
        return false;
      }
    }
  }

  return true;
};

export const waitlistedCourseInGivenTimePeriod = (user) => {
  const userCourseStatuses = user?.userCourseStatus || [];

  for (let userCourseStatus of userCourseStatuses) {
    const courseSlug = userCourseStatus?.course?.slug;
    if (
      userCourseStatus?.current_status?.status ===
      UserCourseStatusEnum.getValue("waitlisted")
    ) {
      const numberOfDays = givenDatesDaysDifference(
        userCourseStatus?.current_status?.createdAt,
        new Date()
      );

      if (numberOfDays <= SHOW_SCHOLARSHIP_PLUG_AFTER_DAYS) {
        return courseSlug;
      }
    }
  }
};
